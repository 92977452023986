import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from '../config';
import { collection, doc, setDoc, deleteDoc, getDoc, Timestamp } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { NavBar } from '../components/NavBar/NavBar';
import { TransitionGroup } from 'react-transition-group';
import { Zoom, Fade, Tooltip, Button } from '@mui/material';
import { Loader } from '../components/Loader/Loader';
import { makeStyles, createStyles } from '@mui/styles';
import { CannonPlayMode } from '../components/CannonPlayMode/CannonPlayMode';
import { shotCannon, Cannon } from '../components/Cannon/Cannon';
import { ShowWinner } from '../components/UserAvatar/ShowWinner';
import { useStorage } from '../hooks/useStorage';
import { useAdminAccess } from '../hooks/useAdminAccess';
import { useValidUser } from '../hooks/useValidUser';
import { UserProfilePicture } from '../components/UserAvatar/UserProfilePicture';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      minHeight: '100%',
    },
    participantsSection: {
      width: '41%',
      padding: '10px',
      minHeight: '100%',
    },
    participantsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    cannonSectionWrapper: {
      padding: '20px',
    },
    loaderWrapper: {
      display: 'flex',
      minHeight: '90vh',
      width: '100vw',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonsSection: {
      display: 'flex',
      flexDirection: 'column',
      width: '5%',
      padding: '20px',
      justifyContent: 'center',
      minHeight: '100%',
    },
    cannonSection: {
      paddingTop: '250px',
    },
    sectionsWrapper: {
      display: 'flex',
      height: '100%',
      width: '100%',
      minHeight: '100%',
    },
  })
);

export const Main = () => {
  const { isAdmin, user } = useStorage();
  useAdminAccess(user, isAdmin);
  useValidUser(user);

  const classes = useStyles();

  const participantsCollection = collection(firestore, 'participants');
  const winnerCollection = collection(firestore, 'winner');
  const stateCollection = collection(firestore, 'state');
  const gameInitiatorCollection = collection(firestore, 'initiator');


  const [participants, participantsLoading] = useCollectionData(participantsCollection);
  const [gameInitiator, gameInitiatorLoading] = useCollectionData(gameInitiatorCollection);
  const [cannonState, cannonStateLoading] = useCollectionData(stateCollection);
  const [winner, winnerLoading] = useCollectionData(winnerCollection);
  
  const [isCannonStoped, setIsCannonStoped] = useState(false);

  const updateWinners = async (currentWinner) => {
    const winnersDoc = doc(firestore, 'winners', currentWinner.email);
    const winner = await getDoc(winnersDoc);

    let counter = 1;
    let wins = [];
    const currentWin = {
      date: Timestamp.fromDate(new Date())
    };

    if (winner.exists()) {
      const winnerInformation = winner.data();
      counter = winnerInformation.winsAmount + 1;
      wins = winnerInformation.wins;
    }

    wins.push(currentWin);

    currentWinner.winsAmount = counter;
    currentWinner.wins = wins;
    currentWinner.lastWinDate = currentWin.date;

    await setDoc(winnersDoc, currentWinner);
  };

  useEffect(() => {
    const startCannonAnimation = async () => {
      const isCannonAnimationStoped = await shotCannon();
      setIsCannonStoped(isCannonAnimationStoped);
    }

    if (!cannonStateLoading && cannonState.length === 1) {
      startCannonAnimation();
    }

  }, [cannonStateLoading, cannonState]);

  useEffect(() => {
    const removeCannonState = async() => {
      const stateDoc = doc(firestore, 'state', 'state_id')
      await deleteDoc(stateDoc);
    };

    const getWinner = async () => {
      const randomParticipantNumber = Math.floor(
        Math.random() * participants.length
      );
      const currentWinner = participants[randomParticipantNumber];
      const winnerDoc = doc(firestore, 'winner', currentWinner.email);
      await setDoc(winnerDoc, currentWinner);
      await updateWinners(currentWinner);
    }

    if (isCannonStoped && !winnerLoading && winner.length === 0 && !gameInitiatorLoading && gameInitiator.length === 1 && gameInitiator[0].email === user.email) {
      setTimeout(() => {
        getWinner();
        removeCannonState();
        setIsCannonStoped(false);
      }, 1000)
    }
  }, [isCannonStoped, winnerLoading, winner, participants, gameInitiator, gameInitiatorLoading, user]);

  const startGame = async () => {
    const stateDoc = doc(firestore, 'state', 'state_id');
    await setDoc(stateDoc, { isValid: true });
    const gameInitiatorDoc = doc(firestore, 'initiator', user.email);
    await setDoc(gameInitiatorDoc, {email: user.email});
  }

  const removeWinner = async() => {
    const existsWinner = !winnerLoading && winner.length === 1;
    const existsGameInitiator = !gameInitiatorLoading && gameInitiator.length === 1;
    if (existsWinner && existsGameInitiator){
      const winnerDoc = doc(firestore, 'winner', winner[0].email)
      await deleteDoc(winnerDoc);
      const gameInitiatorDoc = doc(firestore, 'initiator', gameInitiator[0].email);
      await deleteDoc(gameInitiatorDoc);
    }
  }; 

  const SHOW_START_BUTTON =  isAdmin &&  !winnerLoading && winner.length === 0 && gameInitiator && gameInitiator.length === 0;
  const SHOW_RESTART_BUTTON = isAdmin && !winnerLoading && winner.length > 0;

  return (
    <div className={classes.wrapper}>
      <NavBar/>
      <div>
        {participantsLoading && (
          <div className={classes.loaderWrapper}>
            <Loader />
          </div>
        )}
        {participants && (
          <div className={classes.sectionsWrapper}>
            <div className={classes.participantsSection}>
              <h2>Participants</h2>
              {!winner?.length && (
                <TransitionGroup className={classes.participantsWrapper}>
                  {participants.map(({ image, name, email }) => {
                    return (
                      <Zoom key={email}>
                        <Tooltip
                          placement='top'
                          title={name}
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 300 }}
                          arrow
                        >
                          <div>
                            <UserProfilePicture
                              width={65}
                              height={65}
                              alt={name}
                              src={image}
                            />
                          </div>
                        </Tooltip>
                      </Zoom>
                    );
                  })}
                </TransitionGroup>
              )}
              {!winnerLoading && winner.length > 0 && (
                <ShowWinner winner={winner[0]} />
              )}
            </div>

            <div className={classes.buttonsSection}>
              {SHOW_START_BUTTON && (
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={startGame}
                >
                  START
                </Button>
              )}
              {SHOW_RESTART_BUTTON && (
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={removeWinner}
                >
                  RESTART
                </Button>
              )}
            </div>
            <div className={classes.cannonSection}>
              {!cannonStateLoading && cannonState && cannonState.length > 0 && <Cannon /> }
              {!cannonStateLoading && cannonState && cannonState.length === 0 && <CannonPlayMode />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
