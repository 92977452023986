import { useState } from "react";
import "./StylesPlayMode.css";

const imagesPath = [
  '/1.png',
  '/2.png',
  '/3.png',
  '/4.jpg'
];

export const CannonPlayMode = () => {

  const firstFunctionTimeOut = (fire) => {
    fire.classList.add('hide-play');
    const cannon = document.querySelector('.cannon-play');
    if (!cannon) return;
    cannon.classList.add('shake-play');
  };
  
  const secondFunctionTimeOut = () => {
    const feliz = document.querySelector('.feliz-play');
    if (!feliz) return;
    feliz.classList.remove('hide-play');
    const cannon = document.querySelector('.cannon-play');
    if (!cannon) return;
    cannon.classList.remove('shake-play');
  };

  const thirdFunctionTimeOut = (btn) => {
    let feliz = document.querySelector('.feliz-play');
    if (!feliz) return;
    feliz.classList.add('hide-play');
    btn.classList.remove('hide-play');
  };

  const [randonImage, setRandomImage] = useState();

  const fakeShot = () => {
    
    setRandomImage(imagesPath[Math.floor(Math.random() * imagesPath.length)]);
  
    let fire = document.querySelector('.fire-play');
    
    if (!fire) return;

    fire.classList.remove('hide-play');

    let btn = document.querySelector('.btn');

    if (!btn) return;
      
    btn.classList.add('hide-play');
    
    setTimeout(() => firstFunctionTimeOut(fire), 4000);
    setTimeout(secondFunctionTimeOut, 6500);
    setTimeout(() => thirdFunctionTimeOut(btn), 14000)
  };

  return (
    <div className="container-play">
      <div>
          <img className="cannon-play" src="/cannon.jpg" alt=""></img>
      </div>
      <div>
          <img className="smoke-play hide-play" src="/smoke.png" alt=""></img>
          <img className="feliz-play hide-play" src={randonImage} alt=""></img>
      </div>
      <div className="fire-play hide-play">
          <div className="red-play flame-play"></div>
          <div className="orange-play flame-play"></div>
          <div className="yellow-play flame-play"></div>
          <div className="white-play flame-play"></div>
      </div>
      <div>
        {
          <button className="btn" onClick={fakeShot}>Play!</button>
        }
      </div>
    </div>
  );
};
