import { signOut } from "firebase/auth";
import { firestore, auth } from "../../config";
import { deleteDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import { useStorage } from '../../hooks/useStorage';

export const SignOutButton = () => {
  const navigate = useNavigate();

  const { user } = useStorage();

  const signOutParticipant = async (id) => {

    try {
      navigate("/login", { replace: true });
      const participantsDoc = doc(firestore, "participants", id);
      await deleteDoc(participantsDoc);
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('user');
      await signOut(auth);
    }catch (error) {
      console.log(error);
    }
  };

  return (
    <Button color="inherit" onClick={() => signOutParticipant(user.email)}>
      Sign Out
    </Button>
  );
};
