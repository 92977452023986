import { useNavigate } from 'react-router';
import { collection, doc, deleteDoc, setDoc } from 'firebase/firestore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles, createStyles } from '@mui/styles';
import { NavBar } from '../components/NavBar/NavBar';
import { Loader } from '../components/Loader/Loader';
import { firestore } from '../config';
import { useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useStorage } from '../hooks/useStorage';
import { useAdminAccess } from '../hooks/useAdminAccess';
import { useValidUser } from '../hooks/useValidUser';
import { CustomModal } from '../components/CustomModal/CustomModal';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';


import TextField from '@mui/material/TextField';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { ToastAlert } from '../components/Toast/Alert';
import { UserProfilePicture } from '../components/UserAvatar/UserProfilePicture';

const useStyles = makeStyles(() => 
  createStyles({
    tableSection: {
      padding: '10px 20%'
    },
    loaderWrapper: {
      display: 'flex',
      minHeight: '90vh',
      width: '100vw',
      alignItems: 'center',
      justifyContent: 'center',
    }
  })
);

const deleteParticipantModal = (user = {}) => {
  return {
    title: 'Delete Participant',
    description: `Are you sure to delete "${user.name}?"`,
    confirmButtonText: 'Yes, Delete!',
    cancelButtonText: 'No, cancel!',
    confirmButtonIcon: <DeleteIcon />
  }
};

const revokeAccessModalProps = (admin) => {
  return {
    title: 'Revoke Admin Access',
    description: `Are you sure to revoke admin access for ${admin}?`,
    confirmButtonText: 'Yes, revoke access!',
    cancelButtonText: 'No, cancel!',
    confirmButtonIcon: <DoneIcon />
  }
};

const deleteMultipleParticipantsModalProps = {
  title: 'Delete Multiple Participants',
  description: 'Are you sure to delete all selected participants?',
  confirmButtonText: 'Yes, delete all!',
  cancelButtonText: 'No, cancel!',
  confirmButtonIcon: <DeleteSweepIcon />
};

const revokeMultipleAccessModalProps = {
  title: 'Revoke Multiple Admin Access',
  description: 'Are you sure to revoke admin access to all selected users?',
  confirmButtonText: 'Yes, revoke access!',
  cancelButtonText: 'No, cancel!',
  confirmButtonIcon: <AdminPanelSettingsIcon />
};

const addNewAdminModalProps = {
  title: 'Add New Admin',
  description: 'Please provide the email of the user you wish to have admin access',
  confirmButtonText: 'Grant admin access!',
  cancelButtonText: 'No, cancel!',
  confirmButtonIcon: <GroupAddIcon />
};

export const AdminDashboard = () => {
  const { isAdmin, user } = useStorage();
  useAdminAccess(user, isAdmin);
  useValidUser(user);

  const adminsCollection = collection(firestore, 'admins');
  const participantsCollection = collection(firestore, 'participants');

  const [emailError, setEmailError] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [participantToDelete, setParticipantToDelete] = useState();
  const [adminToRevokeAccess, setAdminToRevokeAccess] = useState();
  const [successdeleteParticipant, setSuccessdeleteParticipant] = useState(false);
  const [successRevokeAdminAccess, setSuccessRevokeAdminAccess] = useState(false);
  const [newAdmin, setNewAdmin] = useState('');
  const [openDeleteParticipantModal, setOpenDeleteParticipantModal] = useState(false);
  const [openRevokeAccessModal, setOpenRevokeAccessModal] = useState(false);
  const [openDeleteMultipleModal, setOpenDeleteMultipleModal] = useState(false);
  const [openRevokeMultipleModal, setOpenRevokeMultipleModal] = useState(false);
  const [openAddNewAdminModal, setOpenAddNewAdminModal] = useState(false);
  const [value, setValue] = useState('1');

  const [participants, participantsLoading] = useCollectionData(participantsCollection);
  const [admins, adminsLoading] = useCollectionData(adminsCollection);

  const classes = useStyles();
  const navigate = useNavigate();

  const isParticipantAnAdmin = (email) => !!admins.find((admin) => admin.email === email);
  
  const handleOpen = (userToDelete) => {
    setParticipantToDelete(userToDelete);
    setOpenDeleteParticipantModal(true);
  }

  const handleClose = () => {
    setParticipantToDelete();
    setOpenDeleteParticipantModal(false);
  }

  const deleteParticipant = async() => {
    setOpenDeleteParticipantModal(false)
    const participantsDoc = doc(firestore, 'participants', participantToDelete.email)
    await deleteDoc(participantsDoc);
    setParticipantToDelete();
    setSuccessdeleteParticipant(true);
  };

  const handleOpenRevokeAccessModal = (adminToRevokeAccess) => {
    setAdminToRevokeAccess(adminToRevokeAccess);
    setOpenRevokeAccessModal(true);
  }

  const handleCloseRevokeAccessModal= () => {
    setAdminToRevokeAccess();
    setOpenRevokeAccessModal(false);
  }

  const revokeAccess = async () => {
    setOpenRevokeAccessModal(false);
    await revokeAdminAccess(adminToRevokeAccess);
    setSuccessRevokeAdminAccess(true);
    setAdminToRevokeAccess();
  }

  const deleteMultipleParticipants = async () => {
    const participantsPromises = [];

    selectedParticipants.forEach(participant => {
      const participantsDoc = doc(firestore, 'participants', participant)
      participantsPromises.push(deleteDoc(participantsDoc));
    });

    setOpenDeleteMultipleModal(false);

    await Promise.all(participantsPromises);
  };
  
  const revokeMultipleAccess = async () => {
    const adminsPromises = [];

    selectedAdmins.forEach(admin => {
      const adminDoc = doc(firestore, 'admins', admin)
      adminsPromises.push(deleteDoc(adminDoc));
    });

    setOpenRevokeMultipleModal(false);

    await Promise.all(adminsPromises);
  };

  const changeAdminAccess = async(event, email) => {
    const provideAdminAccess = event.target.checked;

    if (provideAdminAccess) {
      await grantAdminAccess(email);
    } else {
      await revokeAdminAccess(email);
    }
  };

  const grantAdminAccess = async(email) => {
    const adminDoc = doc(firestore, 'admins', email);
    await setDoc(adminDoc, {email: email});
  };

  const grantAdminAccessByInput = async(email) => {
    setOpenAddNewAdminModal(false)
    await grantAdminAccess(email);
  }

  const revokeAdminAccess = async(email) => {
    const adminDoc = doc(firestore, 'admins', email)
    await deleteDoc(adminDoc);
  };

  const validateEmail = (email) => {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;

    if (email.length < 5) {
      setEmailError(false);
      setNewAdmin('');
      return;
    };

    const isEmailValid = validateEmail(email);

    if (!isEmailValid) {
      setEmailError(true);
      return;
    }

    setEmailError(false);
    setNewAdmin(email);
  };

  const handleEmailCancel = () => {
    setEmailError(false);
    setNewAdmin('');
    setOpenAddNewAdminModal(false)
  }

  const participantsColumns = [
    { 
      field: 'photo', 
      headerName: 'Photo', 
      width: 90,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <div>
            <UserProfilePicture 
              width={30}
              height={30}
              alt={params.row.name}
              src={params.row.image}
            />
          </div>
        )
      } 
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'isAdmin',
      type: 'boolean',
      headerName: 'Is Admin',
      width: 180,
      disableExport: true,
      valueGetter: (params) => isParticipantAnAdmin(params.row.email),
      renderCell: (params)=>{
        return (
          <div>
            <Switch
              onChange={(event) => changeAdminAccess(event, params.row.email)}
              checked={isParticipantAnAdmin(params.row.email)}
              size='small' 
              disabled={params.row.email === user.email}
            />
          </div>
        )
      } 
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 180,
      filterable: false,
      disableExport: true,
      renderCell: (params)=>{
        return (
          <div>
            <Button 
              variant='outlined'
              color='error'
              disabled={params.row.email === user.email}
              onClick={() => handleOpen(params.row)} 
              startIcon={<DeleteIcon />} 
              size='small'
            >
              Delete
            </Button>
          </div>
        )
      } 
    }
  ];

  const adminsColums = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      filterable: false,
      disableExport: true,
      renderCell: (params)=>{
        return (
          <div>
            <Button 
              variant='outlined'
              color='error'
              disabled={params.row.email === user.email}
              onClick={() => handleOpenRevokeAccessModal(params.row.email)} 
              startIcon={<AdminPanelSettingsIcon />} 
              size='small'
            >
              Revoke admin access
            </Button>
          </div>
        )
      } 
    }
  ]

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 10px' }}>
          <div>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
          </div>
          <div>
            <Fab size="small" color="primary" aria-label="add" onClick={() => setOpenAddNewAdminModal(true)}>
              <AddIcon />
            </Fab>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }
  
  useEffect(() => {
    if (!isAdmin) {
      navigate('/', { replace: true });
    }
  }, [isAdmin, navigate]);

  return (
    <div>
      <NavBar/>
      {(participantsLoading || adminsLoading) ? (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={classes.tableSection}>
            <h2>Admin Dashboard:</h2>
            <p>
              Hey {user.displayName} &#x1F44B;, here you can manage all the resources of <b>!Gift Cannon App!</b>
            </p>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={(_, newValue) => setValue(newValue)} aria-label="lab API tabs example">
                    <Tab label="Participants" value="1" />
                    <Tab label="Admins" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p style={{margin: '10px 0'}}>Delete participants or grant/revoke administrator access to participants.</p>
                    { selectedParticipants.length > 0 &&
                        <Button 
                          style={{marginBottom: '10px'}}
                          variant='outlined'
                          color='error'
                          onClick={() => setOpenDeleteMultipleModal(true)} 
                          startIcon={<DeleteIcon />} 
                          size='small'
                        >
                          Delete all selected participants
                        </Button>
                    }
                  </div>
                  <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                      rows={participants}
                      columns={participantsColumns}
                      getRowId={(participant) => participant.email}
                      pageSize={8}
                      rowsPerPageOptions={[8]}
                      checkboxSelection
                      disableSelectionOnClick
                      rowLength={100}
                      isRowSelectable={(params) => params.row.email !== user.email}
                      components={{ Toolbar: GridToolbar }}
                      onSelectionModelChange={(newParticipantsSelected) => {
                        setSelectedParticipants(newParticipantsSelected);
                      }}
                      selectionModel={selectedParticipants}
                    />
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p style={{margin: '10px 0'}}>Grant/Revoke administrator access.</p>
                    { selectedAdmins.length > 0 &&
                        <Button 
                          style={{marginBottom: '10px'}}
                          variant='outlined'
                          color='error'
                          onClick={() => setOpenRevokeMultipleModal(true)} 
                          startIcon={<AdminPanelSettingsIcon />} 
                          size='small'
                        >
                          Revoke admin access for selected users
                        </Button>
                    }
                  </div>
                  <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                      rows={admins}
                      columns={adminsColums}
                      getRowId={(admin) => admin.email}
                      pageSize={8}
                      rowsPerPageOptions={[8]}
                      checkboxSelection
                      disableSelectionOnClick
                      rowLength={100}
                      isRowSelectable={(params) => params.row.email !== user.email}
                      components={{ Toolbar: CustomToolbar }}
                      onSelectionModelChange={(newAdminsSelected) => {
                        setSelectedAdmins(newAdminsSelected);
                      }}
                      selectionModel={selectedAdmins}
                    />
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </>
      )}
      <div>
        <CustomModal 
          {...deleteParticipantModal(participantToDelete)} 
          open={openDeleteParticipantModal} 
          handleClose={handleClose}
          handleConfirm={deleteParticipant}
          handleCancel={handleClose} 
        />

        <CustomModal 
          {...revokeAccessModalProps(adminToRevokeAccess)} 
          open={openRevokeAccessModal} 
          handleClose={handleCloseRevokeAccessModal}  
          handleConfirm={revokeAccess}
          handleCancel={handleCloseRevokeAccessModal} 
        />

        <CustomModal 
          {...deleteMultipleParticipantsModalProps} 
          open={openDeleteMultipleModal} 
          handleClose={() => setOpenDeleteMultipleModal(false)}
          handleConfirm={deleteMultipleParticipants}
          handleCancel={() => setOpenDeleteMultipleModal(false)} 
        />

        <CustomModal 
          {...revokeMultipleAccessModalProps}
          open={openRevokeMultipleModal} 
          handleClose={() => setOpenRevokeMultipleModal(false)}
          handleConfirm={revokeMultipleAccess}
          handleCancel={() => setOpenRevokeMultipleModal(false)} 
        />

        <CustomModal 
          {...addNewAdminModalProps}
          open={openAddNewAdminModal} 
          handleClose={handleEmailCancel}
          handleConfirm={() => grantAdminAccessByInput(newAdmin) }
          handleCancel={handleEmailCancel}
          color='success'
          disabled={emailError || newAdmin.length < 5}
          children={
            <div style={{margin: '20px 0'}}>
              <TextField error={emailError} fullWidth label="Email" color="primary" focused onChange={handleEmailChange}/>
            </div>
          }
        />

        <ToastAlert 
          open={successdeleteParticipant}
          handleClose={() => setSuccessdeleteParticipant(false)}
          message='Participant successfully deleted!'
        />

        <ToastAlert 
          open={successRevokeAdminAccess}
          handleClose={() => setSuccessRevokeAdminAccess(false)}
          message='Admin access succesfully revoked!'
        />
      </div>
    </div>    
  );
}