import { Routes, Route, Navigate, BrowserRouter} from "react-router-dom";
import { Main } from "../pages/Main";
import { AdminDashboard } from "../pages/AdminDashboard";
import { WinnerBoard } from "../pages/WinnerBoard";
import Login from "../pages/Login";

export const AppRouter = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path='/' element={ <Main /> }/>
        <Route  path="/admin-settings" element={ <AdminDashboard /> } />
        <Route  path="/winner-board" element={ <WinnerBoard /> } />
        <Route
          path="*"
          element={<Navigate to="/" />}
        />
      </Routes>
    </BrowserRouter>
  )
};