import { makeStyles, createStyles } from '@mui/styles';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const useStyles = makeStyles(() => 
  createStyles({
    modalButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: '10px',
    }
  })
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};


export const CustomModal = ({
  open,
  handleClose,
  title,
  description,
  confirmButtonText, 
  handleConfirm,
  cancelButtonText,
  handleCancel,
  confirmButtonIcon,
  children,
  color='error',
  disabled=false
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          { title }
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 2 }} component='span'>
          { description }
          {
            children
          }
          <div className={classes.modalButtons}>
            <Button variant='outlined' onClick={handleCancel} startIcon={<HighlightOffIcon />} size='small' style={{marginRight: '5px'}}>
              { cancelButtonText }
            </Button>
            <Button disabled={disabled} variant='outlined' color={color} onClick={handleConfirm} startIcon={confirmButtonIcon} size='small'>
              { confirmButtonText }
            </Button>
          </div>
        </Typography>
      </Box>
    </Modal>
  )
};