import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from "react-router";
import { collection, query, doc, setDoc, where, getDocs } from "firebase/firestore";
import { auth, firestore } from "../../config";
import "./SignInButton.css";

export const SignInButton = () => {
  const navigate = useNavigate();

  const isAdmin = async (email) => {
    const adminsCollection = collection(firestore, 'admins');
    const customQuery = query(adminsCollection, where("email", "==", email));
    const { docs } = await getDocs(customQuery);
    return docs.length > 0;
  }

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const { user } = await signInWithPopup(auth, provider);
      const participantsDoc = doc(firestore, "participants", user.email);
      await setDoc(participantsDoc, {
        name: user.displayName,
        email: user.email,
        image: user.photoURL,
      });
      
      const isUserAdmin = await isAdmin(user.email);
      localStorage.setItem('isAdmin', isUserAdmin);
      localStorage.setItem('user', JSON.stringify(user));

      navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('user');
    }
  };

  return (
    <div className="btn-login-wrap">
      <button className="btn-login" onClick={signInWithGoogle}>
        Login
      </button>
    </div>
  );
};
