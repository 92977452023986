import { useEffect } from "react"
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection } from "firebase/firestore";
import { useNavigate } from 'react-router';
import { signOut } from "firebase/auth";
import { firestore, auth } from '../config';

export const useValidUser = (user) => {

  const navigate = useNavigate();
  const participantsCollection = collection(firestore, 'participants');
  const [participants, participantsLoading] = useCollectionData(participantsCollection);

  useEffect(() => {
    const signOutInvalidUser = async() => {
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('user');
      await signOut(auth);
    };

    if (!user) {
      navigate('/login', { replace: true });
    }

    if (user && !participantsLoading) {
      const isUserAvailableToParticipate = participants.find(participant => participant.email === user.email);

      if (!isUserAvailableToParticipate) {
        navigate('/login', { replace: true });
        signOutInvalidUser();
      }
    }
  }, [user, participants, participantsLoading, navigate]);
};