import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { SignOutButton } from "../SignOut/SignOutButton";
import { useStorage } from '../../hooks/useStorage';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      textAlign: "center",
    },
    logo: {
      width: '20px',
      height: '33x',
      margin: '0px 10px'
    },
    wrapperLogo: {
      display: "flex",
      alignItems: 'center'
    },
    navElement: {
      margin: '0 10px',
      fontWeight: 'normal',
      "&:hover": {
        textDecoration: "underline",
      },
    },
    linkElement: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'black',
    },
    toolBar: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
    
  })
);

export const NavBar = () => {
  const classes = useStyles();
  const { isAdmin } = useStorage();

  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <div className={classes.toolBar}>
          <SignOutButton />
          <Typography variant="h6" className={classes.title}>
            <div className={classes.wrapperLogo}>
              <Link to="/" className={classes.linkElement}>
                <img
                  src={"/ioet-logo-letters.png"}
                  alt="ioet logo!"
                  className={classes.logo}
                />
                <p className={classes.navElement}>Gift Cannon App</p>
              </Link>
              <Divider orientation="vertical" flexItem />
              <Link to="/winner-board" className={classes.linkElement}>
                <p className={classes.navElement}>Winners</p>
              </Link>
              {
                isAdmin && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <Link to="/admin-settings" className={classes.linkElement}>
                      <p className={classes.navElement}>Admin Settings</p>
                    </Link>
                  </>
                )
              }
            </div>
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};
