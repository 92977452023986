import "./Styles.css";

export const shotCannon = () => {
  return new Promise((resolve) => {
    const fire = document.querySelector('.fire');

    if (!fire) return;

    fire.classList.remove('hide');
    
    setTimeout(function() {
      fire.classList.add('hide');
      const cannon = document.querySelector('.cannon');
      if (!cannon) return;
      cannon.classList.add('rotate');
    }, 4000);
    
    setTimeout(function() {
      const smoke = document.querySelector('.smoke');
      if (!smoke) return;
      smoke.classList.remove('hide');
      resolve(true);
    }, 10000);

    setTimeout(function() {
      const smoke = document.querySelector('.smoke');
      if (!smoke) return;
      smoke.classList.add('hide');
      const cannon = document.querySelector('.cannon');
      if (!cannon) return;
      cannon.classList.remove('rotate');
    }, 12000);
  });  
};

export const Cannon = () => {
  return (
    <div className="container">
      <div>
          <img className="cannon" src={"/cannon.jpg"} alt=""></img>
      </div>
      <div>
          <img className="smoke hide" src={"/smoke.png"} alt=""></img>
      </div>
      <div className="fire hide">
          <div className="red flame"></div>
          <div className="orange flame"></div>
          <div className="yellow flame"></div>
          <div className="white flame"></div>
      </div>
    </div>
  );
};
