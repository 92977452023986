import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";

export const ToastAlert = ({
  open,
  handleClose,
  message,
  severity='success'
}) => {
  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
      <Alert onClose={() => {}} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}