import { NavBar } from '../components/NavBar/NavBar';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { makeStyles, createStyles } from '@mui/styles';
import { Loader } from '../components/Loader/Loader';
import { useStorage } from '../hooks/useStorage';
import { collection } from 'firebase/firestore';
import { firestore } from '../config';
import { UserAvatar } from '../components/UserAvatar/UserAvatar';
import TimeAgo from 'react-timeago'

const useStyles = makeStyles(() => 
  createStyles({
    tableSection: {
      padding: '10px 20%'
    },
    loaderWrapper: {
      display: 'flex',
      minHeight: '90vh',
      width: '100vw',
      alignItems: 'center',
      justifyContent: 'center',
    }
  })
);


export const WinnerBoard = () => {
  const { user } = useStorage();
  
  const winnersCollection = collection(firestore, 'winners');
  const [winners, winnersLoading] = useCollectionData(winnersCollection);

  console.log(winners);

  const winnersColumns = [
    { 
      field: 'email', 
      headerName: 'Winner',
      flex: 1,
      renderCell: (params)=>{
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <UserAvatar
              alt={params.row.name}
              src={params.row.image}
              sx={{ width: 30, height: 30 }}
            />
            { params.row.name }
          </div>
        )
      }
    },
    {
      field: 'winsAmount',
      type: 'number',
      headerName: '# Wins',
      flex: 1
    },
    {
      field: 'lastWinDate',
      type: 'date',
      headerName: 'Last win',
      flex: 1,
      valueGetter: (params) => params.row.lastWinDate.toDate(), 
      renderCell: (params)=>{
        return (
          <TimeAgo date={params.row.lastWinDate.toDate()} />
        )
      } 
    }
  ];

  const classes = useStyles();

  return (
    <div>
      <NavBar/>
      {(winnersLoading) ? (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      ) : (
        <div className={classes.tableSection}>
          <h2>Winners Leaderboard:</h2>
          <p>Hey {user.displayName} &#x1F44B;, here you can view all <b>Gift Cannon App</b> winners</p>
          <div style={{ height: 600, width: '100%' }}>
            <DataGrid
              rows={winners}
              columns={winnersColumns}
              getRowId={(winner) => winner.email}
              pageSize={8}
              rowsPerPageOptions={[8]}
              checkboxSelection={false}
              disableSelectionOnClick
              rowLength={100}
              components={{ Toolbar: GridToolbar }}
            />
          </div>
        </div>
      )}
    </div>
  )
};