import { useEffect } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import { firestore } from '../config';
import { useNavigate } from 'react-router';

export const useAdminAccess = (user, isAdmin) => {
  const navigate = useNavigate();
  const adminsCollection = collection(firestore, 'admins');
  const [admins, adminsLoading] = useCollectionData(adminsCollection);

  useEffect(() => {
    if (!adminsLoading) {
      const hasAdminAccess = !!admins.find((admin) => admin.email === user.email);
      if (isAdmin !== hasAdminAccess) {
        localStorage.setItem('isAdmin', hasAdminAccess);
        navigate(0);
      }
    }
  }, [adminsLoading, admins, user, isAdmin, navigate]);
}