import { UserAvatar } from '../../components/UserAvatar/UserAvatar';

export const UserProfilePicture = ({ width, height, alt, src }) => {
  return (
    <UserAvatar sx={{ width: width, height: height }}>
      <img
        width={width}
        height={height}
        referrerPolicy="no-referrer"
        alt={alt}
        src={src}
        style={{objectFit: 'contain'}}
      />
    </UserAvatar>
  )
};