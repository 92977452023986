import { createStyles, makeStyles } from "@mui/styles";
import "./ShowWinner.css";
import { Avatar } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    redText: {
      color: "#cc0005",
    },
    winnerWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      height: "65vh",
      fontSize: "20px",
      width:"50vw",
      alignContent: "center",
      justifyContent: "center",
    }
  })
);

export const ShowWinner = ({ winner, width = 150, height = 150 }) => {
  const classes = useStyles();

  if(!winner) return null;

  return (
    <div
      className={`winnerAnimation ${classes.winnerWrapper}`}
      >
      <Avatar alt={winner.name} src={winner.image} sx={{ width, height }} />
      <h1>
        <span className={classes.redText}>THE WINNER IS</span> {winner.name}
      </h1>
    </div>
  );
};
