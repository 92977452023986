import { styled } from "@mui/styles";
import { Avatar } from '@mui/material';

export const UserAvatar = styled(Avatar)({
  display: "inline-flex",
  margin: "0.5rem",
  border: "2px groove #8B4389",
  '&:hover': {
    border:"2px groove #5ABEEE",
    transform: "scale(1.3) rotate(8deg) !important",
    transition: "ease-out 0.35s !important",
    cursor:"pointer"
  }
});