import { useNavigate } from "react-router";
import { useEffect } from "react";
import { SignInButton } from "../components/SignIn/SignInButton";
import { Paper, Grid  } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { useStorage } from "../hooks/useStorage";

const useStyles = makeStyles(() =>
  createStyles({
    signInWrapper: {
      display: "flex",
      minHeight: "90vh",
      width: "100vw",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: "40px",
      fontWeight: "bold",
      textAlign: "center",
    },
    wrapperLogo: {
      textAlign:"center"
    }
  })
);


const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useStorage();

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  return (
    <div className={classes.signInWrapper}>
      <Paper elevation={3} style={{ padding: "4rem" }}>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item md={6} className={classes.wrapperLogo}>
            <img alt="logo ioet" src="/ioet-logo.png" />
          </Grid>
          <Grid item md={6}>
            <div>
              <h1 className={classes.title}>
                T-SHIRT CANNON
              </h1>
              <SignInButton />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Login;
